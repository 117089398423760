/* globals zoomSdk */
import React, { useState, useEffect,useCallback } from 'react';
import { useLocation,useSearchParams,NavLink} from "react-router-dom";
import {GetLivestream} from '../api/zoomapi'
//import {getRunningContext} from '../ZoomSDK/ZoomSDK'

const Home = (props) => {
    const [counter, setCounter] = useState(0);
    const [error, setError] = useState(null);
    const [runningContext, setRunningContext] = useState(null);
    const [supportedJsApis, setSupportedJsApis] = useState({});
    const [meetingContext, setMeetingContext] = useState({});
    const [LaunchAppInMeeting, setLaunchAppInMeeting] = useState({});
    const [UserContext, setUserContext] = useState({});
    const [Livestream, setLivestream] = useState({});
    const [SpeakerInfo,setSpeakerInfo]=useState({});
    useEffect(() => {
        async function configureSdk() {
          // to account for the 2 hour timeout for config
          const configTimer = setTimeout(() => {
            setCounter(counter + 1);
          }, 120 * 60 * 1000);
    
          try {
            // Configure the JS SDK, required to call JS APIs in the Zoom App
            const configResponse = await zoomSdk.config({
                capabilities: [
                    //APIs
                    "startOrJoinMeeting",
                    "shareApp",
                    "listCameras",
                    "setCamera",
                    "setVideoMirrorEffect",
                    "getMeetingParticipants",
                    "cloudRecording",
                    "getRunningContext",
                    "getMeetingContext",
                    "getSupportedJsApis",
                    "showNotification",
                    "openUrl",
                    "setVirtualBackground",
                    "canSupportVirtualBackground",
                    "listCameras",
                    "setCamera",
                    "sendAppInvitation",
                    "sendAppInvitationToAllParticipants",
                    "getUserContext",
                    "getRecordingContext",
                    "getMeetingContext",
                    "getMeetingJoinUrl",
                    "getMeetingUUID",
                    "expandApp",
                    "connect",
                    "postMessage",
                    "allowParticipantToStartLocalRecording",
                    "launchAppInMeeting",
                    //Events
                    "onShareApp",
                    "onSendAppInvitation",
                    "onCloudRecording",
                    "onActiveSpeakerChange",
                    "onAppPopout",
                    "onCohostChange",
                    "onParticipantChange",
                    "onReaction",
                    "onConnect",
                    "onExpandApp",
                    "onMessage",
                    "onMeeting",
                  ],
            });
            console.log("App configured", configResponse);
            // The config method returns the running context of the Zoom App
            setRunningContext(configResponse);
            zoomSdk.onSendAppInvitation((data) => {
              console.log(data);
            });
            zoomSdk.onShareApp((data) => {
              console.log(data);
            });
          } catch (error) {
            console.log(error);
            setError("There was an error configuring the JS SDK");
          }
          return () => {
            clearTimeout(configTimer);
          };
        }
        configureSdk();
      }, [counter]);
      const getMeetingContext = useCallback(() => {
        zoomSdk
          .getMeetingContext()
          .then((ctx) => {
            setMeetingContext(ctx);
            console.log("Meeting Context", ctx);
          })
          .catch((e) => {
            setMeetingContext(e);
            console.log(e);
          });
            zoomSdk.addEventListener("onActiveSpeakerChange", (event) => {
    try {
       setSpeakerInfo(event)
    } catch (error) {
      console.error(error);
      setError("There was an error showing the notification");
    }
  });
      }, []);

      const getSupportedJsApis = useCallback(() => {
        zoomSdk
          .getSupportedJsApis()
          .then((ctx) => {
            setSupportedJsApis(ctx)
            console.log(ctx);
          })
          .catch((e) => {
            setSupportedJsApis({"error":"There was an error getSupportedJsApis the JS SDK"});
            console.log(e);
          });
      }, []);
      const launchAppInMeeting = useCallback(() => {
         zoomSdk.callZoomApi("launchAppInMeeting").then((ctx) => {
          setLaunchAppInMeeting(ctx)
          console.log(ctx);
        })
        .catch((e) => {
          setLaunchAppInMeeting(e);
          console.log(e);
        });
      }, []);
      const openGoogle = useCallback(() => {
        zoomSdk
          .openUrl({ url: "https://lifehikes.com/" })
          .then(() => {
            console.log("Opened URL successfully");
          })
          .catch((e) => {
            console.log(e);
          });
      }, []);
      
  const showNotification = useCallback(() => {
    async function showNotification() {
      try {
        await zoomSdk.showNotification({
          type: "info",
          title: "Hello Zoom Apps",
          message: "Testing notification",
        });
      } catch (error) {
        console.error(error);
        setError("There was an error showing the notification");
      }
    }
    showNotification();
  }, []);
  const getUserContext = useCallback(() => {
    zoomSdk.callZoomApi("getUserContext")
     .then(function(result){
       setUserContext(result)
  // e.g. { screenName: 'Happy Zoomineer', role: 'host'}
     })
   .catch(function(error){
    setUserContext(error)
  // there was an error
    })
  }, []);
  const getLivestream = useCallback(() => {
    GetLivestream(meetingContext.meetingID).then(res=>{
      setLivestream(res);
    }).catch(err=>{
      setLivestream(err);
    })
  }, []);
  const goToReportPage=useCallback((url)=>{
     window.location.href=url;
  })
    return (
        <div className="App">
            <h1>hello,this is home page</h1>
            <p>
              {/* <a href='/report.html'>Go to report page</a> <a href='/learningPassport.html'>Go to learning passport page</a> */}
            <button onClick={()=>{goToReportPage('/report.html')}}>Go to report page</button>
            <button onClick={()=>{goToReportPage('/learningPassport.html')}}>Go to learning passport page</button>
            <button onClick={()=>{goToReportPage('/hikeinfo.html')}}>Go to hikeinfo page</button>
            {/* <button onClick={()=>{goToReportPage('https://public-dev.lifehikes.com/report/619394296ac97a6b4c1db753')}}>Go to third-party sites</button> */}
            </p>
            <p>runningContext:{JSON.stringify(runningContext)}</p>
            <p>error:{error}</p>
            <button onClick={getSupportedJsApis}>Get supported apis</button>
            <p>supportedJsApis:{JSON.stringify(supportedJsApis)}</p>
            <button onClick={getMeetingContext}>get Meeting Context</button>
            <p style={{color:'red'}}>This API is only available to an app instance being run by a Meeting Owner.</p>
            <p>meetingContext:{JSON.stringify(meetingContext)}</p>
            <button onClick={launchAppInMeeting}>launch App In Meeting</button>
            <p>launchAppInMeeting:{JSON.stringify(LaunchAppInMeeting)}</p>
            <button onClick={openGoogle}>Open url</button>
            <p style={{color:'red'}}>You must whitelist the URL’s domain in your Marketplace app configuration before it can be called with this API.</p>
            <button onClick={showNotification}>Show Notification</button>
            <button onClick={getUserContext}>Get user context</button>
            <p>getUserContext:{JSON.stringify(UserContext)}</p>
            <button onClick={getLivestream}>Get Live stream</button>
            <p>getUserContext:{JSON.stringify(Livestream)}</p> 
            <p>SepakerInfo:{JSON.stringify(SpeakerInfo)}</p>       
        
            </div>
    );
}

export default Home;
