import React, { useState, useEffect } from 'react';
import { useLocation,useSearchParams} from "react-router-dom";
import {AauthToken,login} from '../api/zoomapi'
const OauthToken = (props) => {
    //const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [zoomToken,setZoomToken]=useState({});
    useEffect(() => {
      const code= searchParams.get("code");
      console.info("获取到的code:"+code);
      if(code!==null && code!==undefined)
      {
        AauthToken(code).then(res=>{
          setZoomToken(res);
        }).catch(err=>{

        })
        // var jsonData = new Object();
        // jsonData.username="king.ying@lifehikes.com";
        // jsonData.password="aA12345678!";
        // login(jsonData).then(res=>{
        //     setZoomToken(res);
        //   }).catch(err=>{
  
        //   })
      }
      //console.info(location);
      //console.info(searchParams);
      
    }, []);
    return (
        <div className="App">
            <h1>你好我是OauthToken</h1>
            {
           JSON.stringify(zoomToken)!=="{}"?<p>{JSON.stringify(zoomToken)
            }</p>:null}
        </div>
    );
}

export default OauthToken;
