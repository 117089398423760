import React from 'react';
import Home from './components/Home'
import Policy from './components/Policy'
import Document from './components/Document'
import Support from './components/Support'
import Terms from './components/Terms'
import OauthToken from './components/OauthToken'
const route = [
    { path: '/', element: <Home /> },
    { path: 'document', element: <Document /> },
    { path: 'policy', element: <Policy /> },
    { path: 'support', element: <Support /> },
    { path: 'terms', element: <Terms /> },
    { path: 'oauthToken', element: <OauthToken /> },
];
export default route;