function Document(props) {
    return (
        <div className="App">
            <h1>你好我是Document</h1>

        </div>
    );
}

export default Document;
