function Policy() {
    return (
        <div className="App">
            <h1>你好我是Policy</h1>

        </div>
    );
}

export default Policy;
