import axios from 'axios'
import qs from 'qs'
let ax = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    //withCredentials: true, // 跨域携带证书
    timeout: 30000,
    headers: {
        'content-type': 'application/json;charset=UTF-8',
        'auth': sessionStorage.getItem('token') || ''
    }
})
const itr = (type, url, params) => {
    if (typeof params !== 'object') {
        params = {}
    }
    let arg = qs.stringify(params)
    if (Object.keys(params).length > 0) {
        url = (type === 'get') ? url + '?' + arg : url
    }

    return ax[type](url, params)
}
const base = (type, url, params, loadingFlag, loadingText) => {
    if (loadingFlag) {

    }
    return new Promise((resolve, reject) => {
        itr(type, url, params)
            .then(response => {
                if (loadingFlag) {

                }
                resolve(response.data)
            })
            .catch(error => {
                if (loadingFlag) {

                }
                if (error && error.response) {
                    switch (error.response.status) {
                        case 400:
                            error.message = '错误请求'
                            break
                        case 401:
                            error.message = '未授权，请重新登录'
                            break
                        case 403:
                            error.message = '拒绝访问'
                            break
                        case 404:
                            error.message = '请求错误,未找到该资源'
                            break
                        case 405:
                            error.message = '请求方法未允许'
                            break
                        case 408:
                            error.message = '请求超时'
                            break
                        case 500:
                            error.message = '服务器端出错'
                            break
                        case 501:
                            error.message = '网络未实现'
                            break
                        case 502:
                            error.message = '网络错误'
                            break
                        case 503:
                            error.message = '服务不可用'
                            break
                        case 504:
                            error.message = '网络超时'
                            break
                        case 505:
                            error.message = 'http版本不支持该请求'
                            break
                        default:
                            error.message = `连接错误${error.response.status}`
                    }
                    let errData = {
                            code: error.response.status,
                            message: error.message
                        }
                        // 统一错误处理可以放这，例如页面提示错误...
                        //console.log('统一错误处理: ', errData)
                    reject(errData)
                }
                reject(error.data)
            })
    })
}

export const FormDataFormat = (url, type, FormDataObj, fn, loadingFlag) => {
    if (loadingFlag) {} else {}
    axios({
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        method: type,
        url: url,
        data: FormDataObj
    }).then(response => {
        fn(response)
    })
}
export const Get = (url, params, loadingFlag, loadingText) => {
    return base('get', url, params, loadingFlag, loadingText)
}
export const Post = (url, params, loadingFlag, loadingText) => {
        return base('post', url, params, loadingFlag, loadingText)
    }
    // 是因为 post 和 put 第二个参数是data，所以可以直接在第二个参数的位置写上数据，后台可以访问到,而delete第二个参数是 config ，所以要通过 config 里面的 data 来传参，所以应该这样写：axios.delete('/api',{data:{id:1}})
export const Delete = (url, params, loadingFlag, loadingText) => {
    return base('delete', url, { data: params }, loadingFlag, loadingText)
}
export const Put = (url, params, loadingFlag, loadingText) => {
    return base('put', url, params, loadingFlag, loadingText)
}
export const Patch = (url, params, loadingFlag, loadingText) => {
    return base('patch', url, loadingFlag, loadingText)
}
export const Options = (url, params, loadingFlag, loadingText) => {
    return base('options', url, params, loadingFlag, loadingText)
}