import React, { useState, useEffect } from 'react';
import {ReportPage} from '../api/zoomapi'
const Support = (props) => {
    const [reportPage,setReoprtPage]=useState();
    useEffect(() => {
        ReportPage().then(res=>{
            setReoprtPage(res);
      }).catch(err=>{

      })
        
      }, []);
    return (
        <div dangerouslySetInnerHTML={{__html:reportPage}}>
        </div>
    );
    
}

export default Support;
